import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Section from "../../library/section/section"

import aboutUsStyles from "./about-us.module.scss"
import Button from "../../library/button/button"
import Testimonials from "../testimonials/testimonials"

function AboutUs() {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "amber.jpg" }) {
        id
        childImageSharp {
          sizes {
            src
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <div className={aboutUsStyles.container}>
      <Section>
        <div className={aboutUsStyles.content_wrapper}>
          <div className={aboutUsStyles.about_content}>
            <div>
              <h1 className={aboutUsStyles.header}> Who We Are</h1>
              <p>
                After years of planning events for family and friends, I decided
                to hone in on my passion, master my skills and open my own
                company. Planning and creating are my true passions; I love
                creating and seeing joyous events come to life. I have a giving
                spirit and love to bring joy to others.
              </p>
              <br />

              <p>
                When most people think of hosting an event, they’re bursting
                with excitement as they think of being surrounded by friends,
                family, great food, good entertainment and the perfect venue. We
                all think about the day of the actual event. No one looks
                forward to doing the work that it takes to get to that special
                day. It’s estimated that more than 45% of parties and events go
                way over budget. Between the numerous vendors to coordinate
                with, identifying a theme, food selections, booking
                entertainment, budget tracking and guest lists the burden of
                party and event planning can be stressful and expensive.
              </p>
              <br />
              <p>
                This is exactly why Joyous Events was started. All of the
                coordinating and details can take the joy out of your special
                day and we want to prevent that from happening by relieving you
                of that burden. Let us help make all your events memorable and
                joyous!
              </p>
              <Button
                onClick={() => (window.location.href = "/contact-us")}
                label="Contact Us"
              />
            </div>
          </div>
          <div className={aboutUsStyles.image_container}>
            <Img
              className={aboutUsStyles.image}
              fluid={data.image.childImageSharp.fluid}
              alt="Just testing Image"
            />
          </div>
        </div>
      </Section>
      <Section bgColor="#f6f6f6">
        <Testimonials hideHeader />
      </Section>
    </div>
  )
}

export default AboutUs
