import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero/hero"
import Section from "../library/section/section"
import AboutUs from "../components/about-us/about-us"

function AboutUsPage() {
  return (
    <Layout>
      <SEO title="Home" />
      <Hero
        title="About Us"
        image="https://images.unsplash.com/photo-1513151233558-d860c5398176?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80"
      ></Hero>
      <AboutUs />
    </Layout>
  )
}

export default AboutUsPage
